<template>
  <div v-if="!system" class="h-screen flex items-center justify-center">
    <loader />
  </div>
  <div v-else id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Loader from './components/Loader';

export default {
  components: {
    Loader
  },
  computed: {
    ...mapState(['system'])
  },
  created() {
    this.getSystem()
  },
  methods: {
    ...mapActions(['getSystem'])
  }
};
</script>
