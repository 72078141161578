export default {
  setInitialized(state) {
    state.initialized = true;
  },

  setSystem(state, system) {
    state.system = system;
  },

  setAuthUser(state, user) {
    state.authUser = user;
  },

  setErrorMessage(state, message) {
    state.errorMessage = message;
  },

  setSuccessMessage(state, message) {
    state.successMessage = message;
  },

  setErrorCode(state, code) {
    state.errorCode = code;
  }
};
